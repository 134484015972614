import React from "react";
import StatusScreenProps from "./StatusScreenProps";
import { BodyText, InnerContainer, Title, Wrapper } from "./CommonMessageStyles";
import { Button } from "@kaltura/ds-react-components";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";
import styled from "@emotion/styled";
import clsx from "clsx";

const Button2 = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const ImageContainer = styled("div")(({ theme }) => ({
    textAlign: "center",
    marginTop: theme.spacing(-6),
}));

/**
 * a component that shows a title and some text under it, with optional action buttons
 */
export function StatusScreen({
    className,
    title,
    text,
    image,
    buttonLabel,
    buttonProps,
    button2Label,
    button2Props,
    customButton,
    contentWidth,
}: StatusScreenProps) {
    return (
        <Wrapper className={clsx("kms-ds-status-screen", className)}>
            <InnerContainer contentWidth={contentWidth} className={"kms-ds-status-screen-inner-container"}>
                {!!image && <ImageContainer className={"kms-ds-status-screen-image"}>{image}</ImageContainer>}
                {title && (
                    <Title variant={"h2"} component={"h1"} className={"kms-ds-status-screen-title"}>
                        {title}
                    </Title>
                )}
                {text && (
                    <BodyText align={"center"} variant={"body1"} className={"kms-ds-status-screen-text"}>
                        {HtmlReactParser(text)}
                    </BodyText>
                )}
                <Wrapper className="kms-ds-status-screen-buttons">
                    {customButton && <>{customButton}</>}
                    {!customButton && buttonLabel && (
                        <Button {...buttonProps} className="kms-ds-status-screen-buttons-button1">
                            {buttonLabel}
                        </Button>
                    )}
                    {!customButton && button2Label && (
                        <Button2 {...button2Props} className="kms-ds-status-screen-buttons-button2">
                            {button2Label}
                        </Button2>
                    )}
                </Wrapper>
            </InnerContainer>
        </Wrapper>
    );
}

export default StatusScreen;
