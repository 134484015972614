import { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { useSidebarLayoutContext } from "@kaltura/ds-react-layouts";

let nextId = 0;

export interface EmptyPlayerV3Props {
    /**
     * player config
     * @see https://kaltura.github.io/kaltura-player-js/docs/configuration.html
     */
    config: any;

    /**
     * player error handler
     * @param error
     */
    onError?: (error: { message: string; severity: number }) => void;

    /**
     * player ready handler
     * @param player reference to the player instance
     */
    onReady?: (player: any, playerId: string) => void;
    className?: string;
}

const StyledPlayer = styled("div")({
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
});

export default function EmptyPlayerV3({ onError, onReady, config, className }: EmptyPlayerV3Props) {
    const [player, setPlayer] = useState<any>();
    const playerId = useRef(`js-kaltura-player-${nextId++}`);
    const playerReadyCalled = useRef(false);

    const { isEnabled: isSidebarLayout } = useSidebarLayoutContext();

    const handlePlayerError = (event: any) => {
        if (onError) {
            onError(event.payload);
        }
        else {
            console.log(event.payload);
        }
    };

    useEffect(() => {
        const { KalturaPlayer } = window as any;
        if (!KalturaPlayer) {
            const message = "KalturaPlayer library is not detected. Please include it in your page.";
            handlePlayerError({ message, severity: 3 /* fatal */ });
            return () => void 0;
        }

        let processedConfig = config;

        /*
         * The default position for the floating player is bottom-right.
         * When the sidebar layout is enabled, the sidebar cuts the floating player.
         * There's no support for dynamic floating player positioning in the player,
         * so the only thing we can do is to change the default floating player position to bottom-left.
         * See KMS-28510.
         */
        if (isSidebarLayout) {
            processedConfig = {
                ...processedConfig,
                plugins: {
                    ...processedConfig.plugins,
                    floating: {
                        ...processedConfig.plugins?.floating,
                        position: "bottom-left",
                    },
                },
            };
        }

        try {
            const p = KalturaPlayer.setup({
                ...processedConfig,
                targetId: playerId.current,
            });
            p.addEventListener(p.Event.Core.ERROR, handlePlayerError);

            // delay call to playerReady until after the player is done initializing for real.
            setPlayer(p);

            // returned function will be called on component unmount
            return () => {
                if (p) {
                    p.removeEventListener(p.Event.Core.ERROR, handlePlayerError);
                    p.destroy();
                }
            };
        }
        catch (e) {
            console.log("error setting up player:", e);
        }
        return () => void 0;
    }, []);

    useEffect(() => {
        if (player && onReady && !playerReadyCalled.current) {
            playerReadyCalled.current = true;
            onReady(player, playerId.current);
        }
    }, [player, onReady]);

    return <StyledPlayer id={playerId.current} className={`kaltura-player ${className}`} />;
}
