import { BadgesTabProps } from "../../common/types";
import EarnedBadges from "./earned-badges/EearnedBadges";
import BadgesToEarn from "./badges-to-earn/BadgesToEarn";

export const BadgesTab = (props: BadgesTabProps) => {
    const { badgesToEarn = [], earnedBadges = [] } = props;

    return (
        <div className="kms-ds-personal-badges">
            {earnedBadges.length > 0 && <EarnedBadges badges={earnedBadges} />}
            {badgesToEarn.length > 0 && <BadgesToEarn badges={badgesToEarn} />}
        </div>
    );
};

export default BadgesTab;
