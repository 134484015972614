import React, { useEffect } from "react";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";
import {
    Modal,
    modalClasses,
    ModalCloseButton,
    ModalContent,
    modalContentClasses,
    Typography,
} from "@kaltura/ds-react-components";
import { WebcastEntry } from "@kaltura/mediaspace-shared-types";
import { encodeUnicode, translate, translateTime } from "@kaltura/mediaspace-shared-utils";
import { EventPresentersListNoPopover } from "@kaltura/mediaspace-shared-event-platform-presenter";
import styled from "@emotion/styled";
import ActionSection from "../event-list-item/action-section/ActionSection";

export interface EventListItemModalProps {
    show: boolean;
    onHide: () => void;
    data: WebcastEntry;
    thumbnailUrl: string;
    startDate: number;
    endDate?: number;
    startDateFormat?: string;
    endDateFormat?: string;
    modalBoxMaskColor?: string;
    imageAltText?: string;
    isLive?: boolean;
    inWatchlist?: boolean;
    callToActionButton: React.ReactNode;
    updateMyAgendaStorage?: (sessions: WebcastEntry[]) => void;
    onToggleWatchList?: (id: string, add: boolean) => void;
}

const modalPaperClass = "kms-ds-agenda-modal-paper";

const StyledModal = styled(Modal)({
    [`& .${modalClasses.paper}.${modalPaperClass}`]: {
        border: "none",
    },
});

const ModalBody = styled(ModalContent)({
    maxHeight: "none",
    wordBreak: "break-word",

    [`.${modalContentClasses.root}&`]: {
        padding: 0,
    },
});

const ModalCloseBtn = styled(ModalCloseButton)({
    zIndex: 1,
});

const ThumbnailWrapper = styled("div")(({ theme }) => ({
    width: "100%",
    height: 328,
    display: "flex",
    [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        maxHeight: 211,
    },
}));

const Thumbnail = styled("img")(({ theme }) => ({
    width: "100%",
    height: "auto",
    objectFit: "cover",
    [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        maxHeight: 211,
        borderRadius: 0,
        borderBottom: "none",
    },
}));

const Info = styled("div")(({ theme }) => ({
    padding: theme.spacing(4),
    [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        padding: theme.spacing(2),
    },
}));

const UpdatedAt = styled(Typography)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    color: theme.kaltura.palette.tone3,
}));

const StatusLive = styled("div")(({ theme }) => ({
    color: theme.kaltura.palette.danger.contrastText,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(13),
    borderRadius: theme.kaltura.shape.roundness1,
    display: "inline-block",
    textTransform: "uppercase",
    padding: "1px 3px",
    marginLeft: theme.spacing(1),
    backgroundColor: theme.kaltura.palette.danger.main,
}));

const Name = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone1,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
}));

const Description = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(3),
    color: theme.kaltura.palette.tone1,
    [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        marginTop: theme.spacing(2),
    },
}));

const Action = styled(ActionSection)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));

/**
 * Event List Modal
 */
export const EventListItemModal: React.FC<EventListItemModalProps> = (props: EventListItemModalProps) => {
    const {
        show,
        onHide,
        data,
        thumbnailUrl,
        startDate,
        endDate,
        startDateFormat = "MMMM D, YYYY hh:mm A", //January 23, 2020 09:00 AM - 09:30 AM EST /12 hours
        endDateFormat = "hh:mm A z",
        imageAltText,
        isLive,
        inWatchlist,
        callToActionButton,
        updateMyAgendaStorage,
        onToggleWatchList,
    } = props;

    const { name, description = "", presenters } = data;

    useEffect(() => {
        if (show) {
            document.body.classList.add("event-list-item-modal-open");
        }
        else {
            document.body.classList.remove("event-list-item-modal-open");
        }

        return () => {
            document.body.classList.remove("event-list-item-modal-open");
        };
    }, [show]);

    return (
        <StyledModal
            variant={"content"}
            className="event-list-item-modal"
            open={show}
            onClose={onHide}
            classes={{ paper: modalPaperClass }}
            data-analytics={encodeUnicode(name)}
            aria-labelledby={"kms-ds-event-modal"}
        >
            <ModalCloseBtn variant={"pill"} onClick={onHide} aria-label={translate("close")} />
            <ModalBody>
                <div>
                    <ThumbnailWrapper className="kms-ds-event-list-item-modal-thumbnail">
                        <Thumbnail src={thumbnailUrl} alt={imageAltText || translate("News Image")} />
                    </ThumbnailWrapper>
                    <Info className={"kms-ds-event-list-item-modal-content"}>
                        <UpdatedAt className={"kms-ds-event-list-item-modal-content-date"}>
                            {translateTime(startDate, startDateFormat)}
                            {endDate ? " - " + translateTime(endDate, endDateFormat) : ""}
                            {isLive && (
                                <StatusLive className={"event-list-item__status--live uppercase"}>
                                    {translate("Live")}
                                </StatusLive>
                            )}
                        </UpdatedAt>
                        <Name variant={"h4"} id={"kms-ds-event-modal"}>
                            {name}
                        </Name>
                        <Action
                            data={data}
                            callToActionButton={callToActionButton}
                            updateMyAgendaStorage={updateMyAgendaStorage}
                            showTags={false}
                            inWatchlist={inWatchlist}
                            className={"kms-ds-event-list-item-modal-content-actions"}
                            onToggleWatchList={onToggleWatchList}
                        />
                        <EventPresentersListNoPopover presenters={presenters} />
                        <Description className="kms-ds-event-list-item-modal-content-description">
                            {HtmlReactParser(description)}
                        </Description>
                    </Info>
                </div>
            </ModalBody>
        </StyledModal>
    );
};

export default EventListItemModal;
