import { Button, MenuItem, Modal, ModalActions, ModalContent, ModalTitle } from "@kaltura/ds-react-components";
import { baseUrl, postKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { useState } from "react";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";
import { useToastsContext } from "@kaltura/mediaspace-shared-contexts";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { SharedMenuItemProps } from "@kaltura/mediaspace-shared-types";

interface BaseProps {
    confirmationTitle: string;
    confirmationMessage: string;
    confirmationButtonText: string;
    redirectUrl: string;
}

export interface DeleteMediaMenuItemProps extends BaseProps {
    onDelete: () => Promise<void>;
}

/**
 * An item for ActionsMenu for deleting the media.
 */
export const DeleteMediaMenuItem = ({
    onDelete,
    onCloseMenu,
    confirmationTitle,
    confirmationMessage,
    confirmationButtonText,
    redirectUrl,
    autoFocus,
}: DeleteMediaMenuItemProps & SharedMenuItemProps) => {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [processing, setProcessing] = useState(false);

    const closeConfirmation = () => {
        setOpenConfirmation(false);
        onCloseMenu?.();
    };

    const { showToast } = useToastsContext();

    const handleDelete = async () => {
        setProcessing(true);
        try {
            await onDelete();
            window.location.href = redirectUrl;
            // Note: not calling setProcessing(false) because we're redirecting to another page,
            // and it's better to keep the button in the "processing" state while the page is loading.
        }
        catch (e: unknown) {
            console.error("Failed to delete the media.", e);
            closeConfirmation();
            setProcessing(false);
            showToast({ severity: "error", message: translate("Failed to delete the media") });
        }
    };

    const sendButtonAnalytics = useButtonAnalytics();

    const onMenuButtonClick = () => {
        setOpenConfirmation(true);
        sendButtonAnalytics("Media Actions - Delete", ButtonClickAnalyticsType.DELETE);
    };

    return (
        <>
            <MenuItem onClick={onMenuButtonClick} autoFocus={autoFocus}>
                {translate("Delete")}
            </MenuItem>

            <Modal open={openConfirmation} onClose={closeConfirmation} maxWidth={false}>
                <ModalTitle>{confirmationTitle}</ModalTitle>
                <ModalContent>{HtmlReactParser(confirmationMessage)}</ModalContent>
                <ModalActions>
                    <Button variant={"borderless"} onClick={closeConfirmation}>
                        {translate("Cancel")}
                    </Button>
                    <Button color="danger" onClick={handleDelete} loading={processing} disabled={processing}>
                        {confirmationButtonText}
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export interface DeleteMediaMenuItemContainerProps extends BaseProps {
    entryId: string;
    categoryId?: string;
}

export const DeleteMediaMenuItemContainer = ({
    entryId,
    categoryId,
    ...rest
}: DeleteMediaMenuItemContainerProps & SharedMenuItemProps) => (
    <DeleteMediaMenuItem
        onDelete={() =>
            postKmsData(baseUrl + `/default/media/delete/id/${entryId}${categoryId ? "/categoryid/" + categoryId : ""}`)
        }
        {...rest}
    />
);
