import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import EarnedBadge from "./EearnedBadge";
import { Typography } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { BadgeProps } from "../../../common/types";

type EarnedBadgesProps = {
    badges: BadgeProps[];
};

const StyledEarnedBadges = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
    color: theme.kaltura.palette.tone1,
}));

const StyledList = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
}));

const EarnedBadges = (props: EarnedBadgesProps) => {
    const { badges } = props;
    const badgesCount = badges.length;

    return (
        <StyledEarnedBadges className="kms-ds-personal-badges-earned-badges">
            <Typography variant={"h5"} component={"h2"}>
                {translate("%1 earned badges", [badgesCount])}
            </Typography>
            <StyledList>
                {badges.map((badge: BadgeProps) => (
                    <EarnedBadge name={badge.name} iconUrl={badge.iconUrl} description={badge.description} />
                ))}
            </StyledList>
        </StyledEarnedBadges>
    );
};

export default EarnedBadges;
