import { EventPlatformEntry } from "@kaltura/mediaspace-shared-types";
import React, { useCallback, useState } from "react";
import { IconButton, Tooltip } from "@kaltura/ds-react-components";

export interface EntryListItemActionProps {
    entry: EventPlatformEntry;
    title: string;
    icon: React.ReactNode;
    onClick: (id: string) => void;
}

/**
 * Entry List Item action
 */
export function EntryListItemAction({ entry, title, icon, onClick }: EntryListItemActionProps) {
    const [processing, setProcessing] = useState<boolean>(false);

    const handleClick = useCallback(
        (id: string) => {
            onClick(id);
            setProcessing(true);
        },
        [onClick]
    );

    return (
        <Tooltip title={title}>
            <IconButton
                variant={"borderless"}
                loading={processing}
                aria-label={title}
                onClick={() => handleClick(entry.id)}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
}

export default EntryListItemAction;
