import { cloneElement, ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { ButtonProps, IconButton, Menu, MenuProps } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { MoreVertical24Icon } from "@kaltura/ds-react-icons";

/**
 * Renders a button with a menu items popup.
 *
 * Note: the component doesn't automatically close the menu when clicking the items.
 * If you need to implement this behavior, handle items' onClick and use "onOpen" and "close" props to do it manually.
 * See the "CloseOnItemClick" story for example.
 */
export interface SimpleMenuProps {
    /**
     * menu id
     */
    id: string;

    /**
     * menu contents, expected is `MenuItem`-s
     */
    children: ReactNode;

    /**
     * the icon to display on the (default) menu button.
     * only used if not passing value for `button` prop.
     * expected is one of kaltura-ds-icons
     */
    buttonIcon?: ReactElement;

    /**
     * props passed to the button component
     */
    buttonProps?: ButtonProps;

    /**
     * menu button, expected is Button
     */
    button?: ReactElement;

    /**
     * props passed to the menu component
     */
    menuProps?: Partial<MenuProps>;

    /**
     * optional classname
     */
    className?: string;

    onOpen?: () => void;

    /**
     * close the menu programmatically (please listen to onOpen to know when it opens again!)
     */
    close?: boolean;
}

export function SimpleMenu({
    id,
    children,
    button = <IconButton aria-label={translate("more")} />,
    buttonIcon = <MoreVertical24Icon />,
    buttonProps,
    menuProps,
    className,
    onOpen,
    close,
}: SimpleMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        onOpen?.();
    };
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    useEffect(() => {
        if (close) {
            handleClose();
        }
    }, [close, handleClose]);

    // no point to useMemo here, since the dependencies' default values are recreated on each render.
    const menuButton = cloneElement(
        button,
        {
            children: buttonIcon,
            ...button.props,
            ...buttonProps,
            id: `${id}-btn`,
            [`aria-haspopup`]: true,
            [`aria-expanded`]: open,
            [`aria-controls`]: open ? id : undefined,
            onClick: handleClick,
            className: className,
        }
    );

    return (
        <div className={`${id}-wrapper kms-ds-${id}`}>
            {menuButton}
            <Menu
                MenuListProps={{
                    "aria-labelledby": `${id}-btn`,
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                {...menuProps}
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {children}
            </Menu>
        </div>
    );
}

export default SimpleMenu;
