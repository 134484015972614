import styled from "@emotion/styled";
import { encodeUnicode, translate } from "@kaltura/mediaspace-shared-utils";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";

interface CallToActionImage {
    image: string;
    callToActionLink?: string;
    analyticsString?: string;
    altText?: string;
    openInCurrentTab?: boolean;
    baseClass?: string;
}

export interface AdImagesProps {
    leftImage: CallToActionImage;
    rightImage: CallToActionImage;
    baseClass?: string;
}

const StyledAdImagesContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        flexDirection: "row",
    },
}));

const StyledAdImagesImageContainer = styled("div")(({ theme }) => ({
    width: "100%",
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        marginBottom: 0,
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        marginRight: theme.spacing(4),
    },
    "&:last-child": {
        marginBottom: 0,
        marginRight: 0,
    },
}));

const StyledAdImagesImageWrapperRationSize = styled("div")({
    width: "100%",
    paddingBottom: "56.25%",
    position: "relative",
    height: 0,
});

const StyledAdImagesImageLink = styled("a")(({ theme }) => ({
    display: "block",
    position: "relative",
    bottom: "2px",
    paddingBottom: "56.25%",
    border: "2px solid transparent",
    "&:focus": {
        borderColor: theme.kaltura.palette.primary.main,
        outline: "none",
    },
}));

const StyledAdImagesImage = styled("img")(({ theme }) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
}));
/**
 *  and image or image within link component
 */
const ImageWithLinkComponent = (props: CallToActionImage & { baseClass?: string }) => {
    const { image, callToActionLink = undefined, analyticsString, altText, openInCurrentTab, baseClass = "" } = props;

    const imageComponent = (
        <StyledAdImagesImage
            className={baseClass + "-ad-images-image"}
            alt={altText}
            src={image}
            data-analytics={analyticsString ? encodeUnicode(analyticsString) : ""}
            aria-label={translate("link to advertisement image")}
        />
    );

    const sendButtonAnalytics = useButtonAnalytics();

    const sendAnalytics = () => {
        sendButtonAnalytics("2 Images - Clicked", ButtonClickAnalyticsType.NAVIGATE);
    };

    return callToActionLink ? (
        <StyledAdImagesImageLink
            className={baseClass + "-ad-images-link"}
            href={callToActionLink}
            target={openInCurrentTab ? "_self" : "_blank"}
            data-analytics={analyticsString ? encodeUnicode(analyticsString) : ""}
            onClick={sendAnalytics}
        >
            {imageComponent}
        </StyledAdImagesImageLink>
    ) : (
        imageComponent
    );
};

/**
 * AdImages Component displays two AD images with links
 * See examples in home pages and media page
 */
export const AdImages = (props: AdImagesProps) => {
    const { leftImage, rightImage, baseClass = "kms-ds" } = props;

    return (
        <StyledAdImagesContainer className={baseClass + "-ad-images-container"}>
            <StyledAdImagesImageContainer className={baseClass + "-ad-images-left-image-container"}>
                <StyledAdImagesImageWrapperRationSize className={baseClass + "-ad-images-left-image-wrapper"}>
                    <ImageWithLinkComponent {...leftImage} baseClass={baseClass} />
                </StyledAdImagesImageWrapperRationSize>
            </StyledAdImagesImageContainer>
            <StyledAdImagesImageContainer className={baseClass + "-ad-images-right-image-container"}>
                <StyledAdImagesImageWrapperRationSize className={baseClass + "-ad-images-right-image-wrapper"}>
                    <ImageWithLinkComponent {...rightImage} baseClass={baseClass} />
                </StyledAdImagesImageWrapperRationSize>
            </StyledAdImagesImageContainer>
        </StyledAdImagesContainer>
    );
};

export default AdImages;
