import { Button, ModalContent, ModalTitle, Typography } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { StyledModal, StyledModalActions } from "../ModalStyledComponents";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";

export interface ConfirmModalProps {
    /**
     * function to trigger once user approves
     */
    yesCallback: () => void;

    /**
     * function to trigger once user disapproves
     */
    noCallback: () => void;

    /**
     * label for the "yes" button, defaults to "ok"
     */
    yesButtonLabel?: string;

    /**
     * label for the "no" button, defaults to "cancel"
     */
    noButtonLabel?: string;

    /**
     * prompt title
     */
    title?: string;

    /**
     * prompt text
     */
    text?: string;

    /**
     * is prompt currently showing
     */
    open: boolean;
}

export function ConfirmationModal({
    yesCallback,
    noCallback,
    yesButtonLabel,
    noButtonLabel,
    title,
    text,
    open,
}: ConfirmModalProps) {
    return (
        <StyledModal
            variant="task"
            open={open}
            classes={{ paper: "upload-modal uploadConfirmationModal" }}
            className={"kms-ds-upload-media-modal"}
        >
            {!!title && <ModalTitle>{title}</ModalTitle>}
            {!!text && (
                <ModalContent>
                    <Typography variant={"body1"} align={"center"}>
                        {HtmlReactParser(text)}
                    </Typography>
                </ModalContent>
            )}
            <StyledModalActions>
                <Button variant={"borderless"} onClick={noCallback}>
                    {noButtonLabel || translate("Cancel")}
                </Button>
                <Button onClick={yesCallback}>{yesButtonLabel || translate("OK")}</Button>
            </StyledModalActions>
        </StyledModal>
    );
}

export default ConfirmationModal;
