import styled from "@emotion/styled";
import { Checkbox } from "@kaltura/ds-react-components";
import { OptionProps } from "../types";

export interface OptionsProps {
    options: OptionProps[];
    onFilterUpdate: (value: string) => void;
}

const StyledFilterItemWrapper = styled("div")(({ theme }) => ({
    marginBottom: theme.spacing(2.5),
}));

const StyledFilterItemLabel = styled("label")(({ theme }) => ({
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    color: theme.kaltura.palette.tone2,
}));

/**
 * Channelscollections single Filter
 */
const Options = ({ options = [], onFilterUpdate }: OptionsProps) => {
    return (
        <>
            {options.map((option: OptionProps) => (
                <StyledFilterItemWrapper
                    key={option.value}
                    onClick={() => {
                        onFilterUpdate(option.value);
                    }}
                >
                    <Checkbox id={option.label} checked={option.checked} />
                    <StyledFilterItemLabel htmlFor={option.label}>{option.label.trim()}</StyledFilterItemLabel>
                </StyledFilterItemWrapper>
            ))}
        </>
    );
};

export default Options;
