import { KmsTypeCategoryMembership, KmsTypePublishCategory } from "@kaltura/mediaspace-shared-types";
import { Tooltip } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import {
    Expert24Icon,
    Globe24Icon,
    Group24Icon,
    Lock24Icon,
    NoAccess24Icon,
    Unlock24Icon,
    Visible24Icon,
} from "@kaltura/ds-react-icons";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { CategoryType } from "../../../../../../utils";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";
import { ReactElement, useMemo } from "react";

interface CategoryIconProps {
    category: KmsTypePublishCategory;
    categoryType: CategoryType.channel | CategoryType.gallery;
}

/**
 * Category membership and moderation icons
 */
export function CategoryIcon({ category, categoryType }: CategoryIconProps) {
    const sendButtonAnalytics = useButtonAnalytics();

    const tooltipByCategoryTypeMapping: Partial<
        Record<KmsTypeCategoryMembership, Record<CategoryType.gallery | CategoryType.channel, string>>
    > = useMemo(() => {
        return {
            [KmsTypeCategoryMembership.OPEN]: {
                [CategoryType.channel]: translate(
                    "Open - All logged-in users can view and contribute content (contribution is not allowed for viewer-role users)."
                ),
                [CategoryType.gallery]: translate(
                    "Open - All logged-in users can view and contribute content (contribution is not allowed for viewer-role users).<br/>In case guests are allowed to the site, they can also view content."
                ),
            },
            [KmsTypeCategoryMembership.RESTRICTED]: {
                [CategoryType.channel]: translate(
                    "Restricted - All logged-in users can view content, only channel members can contribute."
                ),
                [CategoryType.gallery]: translate(
                    translate(
                        "Restricted - All logged-in users can view content, only gallery members can contribute.<br/>In case guests are allowed to the site, they can also view content."
                    )
                ),
            },
            [KmsTypeCategoryMembership.PRIVATE]: {
                [CategoryType.channel]: translate("Private - Only channel members can view and contribute content."),
                [CategoryType.gallery]: translate("Private - Only gallery members can view and contribute content."),
            },
        };
    }, []);

    const getTooltipText = (categoryMembership: KmsTypeCategoryMembership): string => {
        switch (categoryMembership) {
            case KmsTypeCategoryMembership.OPEN:
            case KmsTypeCategoryMembership.RESTRICTED:
            case KmsTypeCategoryMembership.PRIVATE:
                return tooltipByCategoryTypeMapping[categoryMembership]?.[categoryType] ?? "";

            case KmsTypeCategoryMembership.SHARED:
                return "Shared Repository - Only members can view and contribute content. Content can be published to other locations based on entitlements.";

            case KmsTypeCategoryMembership.PUBLIC_RESTRICTED:
                return translate(
                    "Public, Restricted - Anyone can view content (including guests). Only channel members can contribute according to their publishing entitlements."
                );

            case KmsTypeCategoryMembership.PUBLIC_OPEN:
                return translate(
                    "Public, Open - Anyone can view content (including guests). All logged in users can contribute content (contribution is not allowed for viewer-role users)."
                );
        }
    };

    const sendAnalytics = () => {
        const actionName = `Publish - Set publishing settings modal box - Info for ${categoryType}`;
        sendButtonAnalytics(actionName, ButtonClickAnalyticsType.EXPAND);
    };

    const getIconWithTooltip = (icon: ReactElement, text: string) => (
        <Tooltip onOpen={sendAnalytics} title={HtmlReactParser(text)}>
            {icon}
        </Tooltip>
    );

    const getMembershipIcon = () => {
        const tooltipText = category.membership ? getTooltipText(category.membership) : "";

        switch (category.membership) {
            case KmsTypeCategoryMembership.OPEN:
                return getIconWithTooltip(<Globe24Icon />, tooltipText);

            case KmsTypeCategoryMembership.RESTRICTED:
                return getIconWithTooltip(<NoAccess24Icon />, tooltipText);

            case KmsTypeCategoryMembership.PRIVATE:
                return getIconWithTooltip(<Lock24Icon />, tooltipText);

            case KmsTypeCategoryMembership.SHARED:
                return getIconWithTooltip(<Group24Icon />, tooltipText);

            case KmsTypeCategoryMembership.PUBLIC_RESTRICTED:
                return getIconWithTooltip(<Unlock24Icon />, tooltipText);

            case KmsTypeCategoryMembership.PUBLIC_OPEN:
                return getIconWithTooltip(<Visible24Icon />, tooltipText);

            default:
                return null;
        }
    };

    const getModerationIcon = () => {
        if (category.moderation) {
            const tooltipText = translate("Moderated - Content will only be available after moderator approval.");
            return getIconWithTooltip(<Expert24Icon />, tooltipText);
        }
        return null;
    };

    const icon = getMembershipIcon();
    const moderationIcon = getModerationIcon();

    return (
        <div className="kms-ds-publish-category-icon">
            {icon}
            {moderationIcon}
        </div>
    );
}
