import styled from "@emotion/styled";
import { Tooltip } from "@kaltura/ds-react-components";
import { KmsTypePresenter } from "@kaltura/mediaspace-shared-types";
import { EventPresenterLargeClasses } from "./eventPresenterLargeClasses";
import { composeClasses } from "@kaltura/mediaspace-shared-styled";
import { getEventPresenterClass } from "../event-presenter/eventPresenterClasses";
import { EventPresenterProps } from "../event-presenter/EventPresenter";
import clsx from "clsx";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { Initials, Typography } from "@kaltura/ds-react-components";
import { TruncatedLine, TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";

export interface EventPresenterLargeProps {
    className?: string;
    /** data to show */
    data: KmsTypePresenter;
    classes?: Partial<EventPresenterLargeClasses>;
}

const PresenterImage = styled("img")(({ theme }) => ({
    borderRadius: theme.kaltura.shape.roundness2,
    width: 64,
    height: 64,
}));

const InitialsContainer = styled("div")(({ theme }) => ({
    width: 64,
    minWidth: 64,
    height: 64,
    "& .kms-ds-event-presenter-thumbnail-initials": {
        borderRadius: theme.kaltura.shape.roundness2,
    },
}));

const Presenter = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    width: "100%",
}));
const Details = styled("div")({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
});

const Name = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone1,
}));

const Title = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
}));
const ContainerDiv = styled("div")({
    lineHeight: 0,
});

const useUtilityClasses = ({ classes }: Partial<EventPresenterProps>) => {
    const slots = {
        root: ["root"],
        details: ["details"],
        name: ["name"],
        title: ["title"],
        thumb: ["thumb"],
    };

    return composeClasses(slots, getEventPresenterClass, classes);
};

export function EventPresenterLarge(props: EventPresenterLargeProps) {
    const { data, className } = props;
    const classes = useUtilityClasses(props);

    return (
        <Presenter className={clsx(classes.root, className)}>
            <Tooltip title={data.name} describeChild={true}>
                <ContainerDiv>
                    {!!data.image && (
                        <PresenterImage
                            className={clsx(classes.thumb, "kms-ds-event-presenter-thumbnail")}
                            src={data.image}
                            aria-hidden="true"
                        />
                    )}
                    {!data.image && (
                        <InitialsContainer
                            className={clsx(classes.thumb, "kms-ds-event-presenter-thumbnail")}
                            aria-hidden="true"
                        >
                            <Initials
                                size={"medium"}
                                fullName={data.name}
                                classes={{
                                    root: "kms-ds-event-presenter-thumbnail-initials",
                                }}
                            />
                        </InitialsContainer>
                    )}
                </ContainerDiv>
            </Tooltip>

            <Details className={classes.details}>
                <Name className={classes.name} variant={"body2Highlight"}>
                    <TruncatedLine>{data.name}</TruncatedLine>
                </Name>
                <TruncateWithTooltip text={data.title} lines={2} tokenize={"words"}>
                    <Title className={classes.title} variant={"body2"}>
                        {data.title}
                    </Title>
                </TruncateWithTooltip>
            </Details>
        </Presenter>
    );
}

export default EventPresenterLarge;
