// Data stubs for Webcast stories and tests
// tslint:disable:max-line-length
import { KmsTypePresenter, WebcastEntry } from "@kaltura/mediaspace-shared-types";

export const presenters: KmsTypePresenter[] = [
    {
        id: "5cb70f7668507",
        name: "Typical Kalturian",
        title: "Sus-worker",
        bio: "",
        link: "",
        image: "https://www.kaltura.com/api_v3/service/thumbasset/action/serve?format=2&clientTag=KMS+5.85.17%2C+build+0&apiVersion=14.19.0&thumbAssetId=0_ebghwooy&thumbParams%5BobjectType%5D=KalturaThumbParams&thumbParams%5BcropType%5D=3&thumbParams%5Bwidth%5D=42&thumbParams%5Bheight%5D=42&partnerId=1974541&kalsig=e78b2912177c593860b4eb8b3d79710e&ks=djJ8MTk3NDU0MXyJRPnNruRsyUyuH-ljHqB0Jr10m2ikRA1f9aiVrplrrx5bepY7YcXlqP5SgQkjQF1S5yT23z31esxNhx3RaMXq1rG9Vow-ClYOANVFiZXiXoSH8FzcrIWdrYXOhM9obV55x71v0JoQPeecskKopDhp",
    },
    {
        id: "5cb70f88a56b3",
        name: "John Bruce",
        title: "Project Manager",
        bio: "",
        link: "",
        image: "https://www.kaltura.com/api_v3/service/thumbasset/action/serve?format=2&clientTag=KMS+5.86.06%2C+build+0&apiVersion=14.19.0&thumbAssetId=1_t1vgni0i&thumbParams%5BobjectType%5D=KalturaThumbParams&thumbParams%5BcropType%5D=3&thumbParams%5Bwidth%5D=42&thumbParams%5Bheight%5D=42&partnerId=2547452&kalsig=9b6eb1a0ce2d80949b6d0901381434a0&ks=djJ8MjU0NzQ1MnyyaZnuyF63R7DM-RSljvSM3air-NqdAnuwMqMnLwsXh69iOXPOIinAg0Jtja8_TWC-1xLNwFobMV2Ixv36pc4Ifp4Ts1q6Y6bUJsMuVjYo8V8qLuDAIrZfKd9im16knG-3oWCEsi3rjJYSLMYToHb8",
    },
    {
        id: "5cb70f88a56b2",
        name: "Guesty",
        title: "Anonymous",
        bio: "",
        link: "",
        image: "https://www.kaltura.com/api_v3/service/thumbasset/action/serve?format=2&clientTag=KMS+5.85.17%2C+build+0&apiVersion=14.19.0&thumbAssetId=0_puy3xeos&thumbParams%5BobjectType%5D=KalturaThumbParams&thumbParams%5BcropType%5D=3&thumbParams%5Bwidth%5D=42&thumbParams%5Bheight%5D=42&partnerId=1974541&kalsig=219de9a13134cd3e9b80091470b5fa10&ks=djJ8MTk3NDU0MXyJRPnNruRsyUyuH-ljHqB0Jr10m2ikRA1f9aiVrplrrx5bepY7YcXlqP5SgQkjQF1S5yT23z31esxNhx3RaMXq1rG9Vow-ClYOANVFiZXiXoSH8FzcrIWdrYXOhM9obV55x71v0JoQPeecskKopDhp",
    },
    {
        id: "5cb70f7668508",
        name: "Frank Boosman",
        title: "Business developer, product marketer, project manager",
        bio: "",
        link: "",
        image: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/1_5pdtky4l/width/100/height/100/type/3",
    },
    {
        id: "5cb70f88a56b9",
        name: "Don Drake",
        title: "Sr. Solutions Architect, DBS",
        bio: "",
        link: "https://www.linkedin.com/in/don-drake-3631?trk=org-employees_profile-result-card_result-card_full-click",
    },
    {
        id: "5cb70f88a56b7",
        name: "Ted Collins",
        title: "CTO at Vitac, the world leader in Closed Captioning services for broadcast and online media",
        bio: "",
        link: "",
        image: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/1_5pdtky4l/width/100/height/100/type/3",
    },
    {
        id: "7368696d695f7461766f7269",
        name: "Shimi Tavori",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua., Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        link: "/profile/eyJpdiI6InBoTnFtdG9ZOWZEWGs2eE9zdlRMNlE9PSIsInZhbHVlIjoiMjFibktLWjNDXC9VZFpmTmY1N28wWXAzV2pveWk5aVZmVGVGck16U2p6SUU9IiwibWFjIjoiZWJhZmJiYmNhM2M3NjlkZTFjODdmY2M4NjdkZGEwOTM0ZTliNmQzOTk4ZTRjMTM1YjRlNjBhMjYxYWZhMWYwMSJ9",
    },
];

export const generateLiveEntry = (entryId: string, presentersCount = 2, startTime = 1556360100): WebcastEntry => {
    return {
        types: ["live"],
        id: entryId,
        name: "Mastering Digital Marketing Strategies: From Content Creation to Lead Conversion",
        description:
            "Register today for an executive webcast with CIO, Mark Sunday, product management, and customer executives as they introduce Documents Cloud Service and discuss the business use cases for cloud content sharing and collaboration. But the following sentence will be truncated.",
        tags: "tag with multiple words,tag2",
        thumbnailUrl: "https://cfvod.kaltura.com/p/976461/sp/97646100/thumbnail/entry_id/1_u4lkoi3u/version/100011",
        createdAt: 1556360593,
        lastBroadcast: Date.now() / 1000,
        duration: 0,
        stats: {},
        presenters: presenters.slice(0, presentersCount),
        schedulingData: {
            start: {
                timestamp: startTime,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: startTime + 3600,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    };
};

export const generateUpcomingEntry = (entryId: string, presentersCount = 2, startTime = 1559231100): WebcastEntry => {
    return {
        types: ["upcoming"],
        id: entryId,
        name: "Real-Time Talent Insights: enhance your organization with amazing analytics powers",
        description:
            "See how Deloitte and Oracle work together to bring you next-generation workforce analytics that will quickly help your organization",
        tags: "some,tag",
        thumbnailUrl: "https://cfvod.kaltura.com/p/976461/sp/97646100/thumbnail/entry_id/1_f41z4daj/version/100032",
        createdAt: 1555947291,
        duration: 0,
        stats: {
            plays: 269,
            votes: 120,
            comments: 5,
        },
        presenters: presenters.slice(0, presentersCount),
        schedulingData: {
            start: {
                timestamp: startTime,
                timeZoneName: "America/New_York",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: startTime + 3600,
                timeZoneName: "America/New_York",
                timeZoneOffset: 10800,
            },
        },
    };
};

export const generateRecordedEntry = (entryId: string, presentersCount = 2, startTime = 1556104500): WebcastEntry => {
    return {
        types: ["recorded"],
        id: entryId,
        name: "Introducing Mobile Cloud",
        tags: "tag1tagg,tag2,tag333",
        thumbnailUrl: "https://cfvod.kaltura.com/p/976461/sp/97646100/thumbnail/entry_id/1_gbuwisjb/version/100031",
        createdAt: 1556105569,
        lastBroadcast: 1556111426,
        duration: 2697,
        description:
            "Join Kaltura Co-founder, President & General Manager, Enterprise & Learning Dr. Michal Tsur and Head of Product Renan Gutman for an exciting look at future video developments. In this session",
        stats: {
            plays: 269,
            votes: 120,
            comments: 5,
        },
        presenters: presenters.slice(0, presentersCount),
        schedulingData: {
            start: {
                timestamp: startTime,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: startTime + 3600,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    };
};
