import { Link, Typography } from "@kaltura/ds-react-components";
import { baseUrl, translate, useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import styled from "@emotion/styled";
import { Box, useTheme } from "@kaltura/mediaspace-shared-styled";
import { BadgeProps } from "../../common/types";
import BadgeToEarn from "./BadgeToEarn";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { MouseEvent } from "react";

type BadgesToEarnProps = {
    badges: BadgeProps[];
};

const StyledContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone3,
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: theme.kaltura.typography.fontWeightBold,
    letterSpacing: 1,
}));

const StyledList = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    flexWrap: "wrap",
}));

const StyledLink = styled(Link)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
}));

const staticClassBase = "kms-ds-header-badges-menu-badges-to-earn";

/**
 * header user badges menu badges-to-earn section component
 */
const BadgesToEarn = (props: BadgesToEarnProps) => {
    const { badges = [] } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const badgesToShow = badges.slice(0, isSmallScreen ? 3 : 4);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleShowMore = (e: MouseEvent<HTMLElement>) => {
        (e.target as HTMLElement).blur();
        sendButtonAnalytics("Show More Badges - Header menu", ButtonClickAnalyticsType.LINK);
    };

    return (
        <StyledContainer className={`${staticClassBase}`}>
            <StyledTypography className={`${staticClassBase}-title`}>{translate("Badges I can earn")}</StyledTypography>
            <StyledList>
                {badgesToShow.map((badge: BadgeProps) => (
                    <BadgeToEarn name={badge.name} iconUrl={badge.iconUrl} description={badge.description} />
                ))}
            </StyledList>

            {badgesToShow.length < badges.length && (
                <StyledLink
                    onClick={handleShowMore}
                    href={baseUrl + "/profile"}
                    target="_blank"
                    rel="noreferrer"
                    className={`${staticClassBase}-view-more-link`}
                    size={"large"}
                >
                    {translate("View more badges")}
                </StyledLink>
            )}
        </StyledContainer>
    );
};

export default BadgesToEarn;
