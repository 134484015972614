import { Add24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@kaltura/mediaspace-shared-utils";
import React, { useState, useRef, useEffect } from "react";
import { BaseInput, Button } from "@kaltura/ds-react-components";
import { StyledCreatePlaylistContainer, StyledForm } from "./StyledComponents";
import { onInputKeyDown } from "../../helpers";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";

export interface CreatePlaylistProps {
    onNew: (name: string) => void;
}

/**
 * modal's create playlist section
 */
const CreatePlaylist = (props: CreatePlaylistProps) => {
    const { onNew } = props;

    const [showInput, setShowInput] = useState<boolean>(false);
    const [newPlaylist, setNewPlaylist] = useState<string>("");

    const inputRef = useRef<HTMLInputElement | null>(null);

    const sendButtonAnalytics = useButtonAnalytics();
    const handleShowForm = () => {
        sendButtonAnalytics(
            "bulk actions - Add to playlist - New playlist flow initiated",
            ButtonClickAnalyticsType.CREATE
        );
        setShowInput(true);
    };

    const handlePlaylistInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setNewPlaylist(value);
    };

    const reset = () => {
        setNewPlaylist("");
        setShowInput(false);
    };

    const handleAddNewPlaylist = () => {
        sendButtonAnalytics("bulk actions - Add to playlist - New playlist created", ButtonClickAnalyticsType.CREATE);
        onNew(newPlaylist);
        reset();
    };
    useEffect(() => {
        if (showInput) {
            inputRef?.current?.focus();
        }
    }, [showInput]);

    return (
        <StyledCreatePlaylistContainer>
            {!showInput && (
                <Button variant="borderless" startIcon={<Add24Icon />} onClick={handleShowForm}>
                    {translate("Create new playlist")}
                </Button>
            )}

            {showInput && (
                <StyledForm>
                    <BaseInput
                        placeholder={translate("Create playlist title")}
                        onChange={handlePlaylistInput}
                        value={newPlaylist}
                        inputRef={inputRef}
                        onKeyDown={onInputKeyDown}
                    />
                    <Button variant="borderless" onClick={handleAddNewPlaylist} disabled={newPlaylist === ""}>
                        {translate("Create")}
                    </Button>
                </StyledForm>
            )}
        </StyledCreatePlaylistContainer>
    );
};

export default CreatePlaylist;
